import React from 'react';

import html from '../assets/html.png'
import css from '../assets/css.png';
import javascript from '../assets/javascript.png';
import reactImage from "..//assets/react.png";
import nextjs from "..//assets/nextjs.png";
import graphql from "..//assets/graphql.png";
import github from "..//assets/github.png";
import tailwind from "..//assets/tailwind.png";


const Experience = () => {
	const techs = [
		{
			id:1,
			src: html,
			title: 'HTML',
			style: 'shadow-orange-500'
		},
		{
			id:2,
			src: css,
			title: 'CSS',
			style: ' shadow-blue-600'
		},
		{
			id:3,
			src: javascript,
			title: 'JavaScript',
			style: ' shadow-yellow-500'
		},
		{
			id:4,
			src: reactImage,
			title: 'React',
			style: ' shadow-purple-500'
		},
		{
			id:5,
			src: tailwind,
			title: 'Tailwind',
			style: ' shadow-blue-400'
		},
		{
			id:6,
			src: nextjs,
			title: 'Next JS',
			style: ' shadow-black'
		},
		{
			id:7,
			src: graphql,
			title: 'GraphQL',
			style: ' shadow-pink-500'
		},
		{
			id:8,
			src: github,
			title: 'Github',
			style: ' shadow-gray-500'
		},
		
	]
	return( <div name='experience' 
		className=' w-full h-full bg-gradient-to-b from-gray-800 to-black text-white '>
	
		<div className=' max-w-screen-lg mx-auto p-4 flex flex-col justify-center min-h-screen'>
			<div className=''>
				<p className=' text-4xl font-bold inline border-b-4 border-gray-500'>Experience</p>
				<p className='py-6'>These are the technologies I've worked with.</p>
			</div>


			<div className=' w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0'>

			{
				techs.map(({id, src, title , style}) =>(
				<div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg justify-center ${style}`}>
					<img src={src} alt=""  className=' w-20 mx-auto'/>
					<p className='mt-4'>{title}</p>
				</div>
			))
			}
				
			</div>
		</div>

	</div>
	)
};

export default Experience;
